<template>
  <div class="wxinner-wrap">
    <background />
    <div class="title">欢迎使用{{ systemInfo.company }}</div>
    <div class="des">链接已失效！！</div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'

  export default {
    data() {
      return {}
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system,
      }),
    },
  }
</script>
<style lang="less" scoped>
  .wxinner-wrap {
    padding-top: 47px;
    .title {
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 1);
      line-height: 30px;
      text-align: center;
      margin-bottom: 19px;
    }
    .hasSend {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 21px;
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(17, 26, 52, 1);
      line-height: 21px;
      margin-bottom: 35px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 11px;
      }
    }
    .des {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(103, 114, 131, 1);
      line-height: 20px;
      text-align: center;
      margin-bottom: 20px;
    }
    .qrCode {
      width: 200px;
      height: 200px;
      display: block;
      margin: 0 auto;
    }
  }
</style>
